import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import asyncComponent from "../../helpers/AsyncFunc";
const routes = [
  {
    path: "",
    component: asyncComponent(() => import("../dashboard")),
  },
  {
    path: "dashboard",
    component: asyncComponent(() => import("../dashboard")),
  },
  {
    path: "blankPage",
    component: asyncComponent(() => import("../blankPage")),
  },
  {
    path: "change-password",
    component: asyncComponent(() =>
      import("../Page/change_password/changePassword")
    ),
  },
  {
    path: "users",
    component: asyncComponent(() => import("../Page/users/users")),
  },
  {
    path: "edit-profile",
    component: asyncComponent(() => import("../Page/edit_profile/editProfile")),
  },
  {
    path: "enquiries",
    component: asyncComponent(() => import("../Page/enquiries/enquiries")),
  },
  {
    path: "static-pages",
    component: asyncComponent(() => import("../Page/static_page/staticPages")),
  },
  {
    path: "food-category",
    component: asyncComponent(() =>
      import("../Page/food_category/foodCategory")
    ),
  },
  {
    path: "area-management",
    component: asyncComponent(() =>
      import("../Page/area_management/areaManagement")
    ),
  },
  {
    path: "area-management/add-area",
    component: asyncComponent(() =>
      import("../Page/area_management/addAreaModal")
    ),
  },
  {
    path: "area-management/:id/edit-area",
    component: asyncComponent(() =>
      import("../Page/area_management/editAreaModal")
    ),
  },
  {
    path: "drivers",
    component: asyncComponent(() => import("../Page/drivers/drivers")),
  },
  {
    path: "coordinators",
    component: asyncComponent(() => import("../Page/coordinator/coordinators")),
  },
  {
    path: "restaurants",
    component: asyncComponent(() => import("../Page/restaurant/restaurants")),
  },
  {
    path: "subcategories",
    component: asyncComponent(() => import("../Page/sub_category/subcategory")),
  },
  {
    path: "admin-setting",
    component: asyncComponent(() =>
      import("../Page/admin_setting/adminSetting")
    ),
  },
  {
    path: "products/:id",
    component: asyncComponent(() => import("../Page/product/products")),
  },
  {
    path: "manager/products/:id",
    component: asyncComponent(() => import("../Page/product/products")),
  },
  {
    path: "restaurant-products",
    component: asyncComponent(() =>
      import("../Page/product/managerPortalProduct")
    ),
  },
  {
    path: "products/add-products/:id",
    component: asyncComponent(() => import("../Page/product/add")),
  },
  {
    path: "add-products/:id",
    component: asyncComponent(() => import("../Page/product/add")),
  },
  {
    path: "restaurants/order/:id",
    component: asyncComponent(() =>
      import("../Page/restaurant/restaurant-orders/orders")
    ),
  },
  {
    path: "restaurants/rejected-order/:id",
    component: asyncComponent(() =>
      import("../Page/restaurant/rejected-order/rejectedOrders")
    ),
  },
  {
    path: "coordinators/order/:id",
    component: asyncComponent(() =>
      import("../Page/coordinator/coordinator-order/orders")
    ),
  },
  {
    path: "users/order/:id",
    component: asyncComponent(() => import("../Page/users/user-order/orders")),
  },
  {
    path: "drivers/order/:id",
    component: asyncComponent(() =>
      import("../Page/drivers/driver-order/orders")
    ),
  },
  {
    path: "orders",
    component: asyncComponent(() => import("../Page/orders/orders")),
  },
  {
    path: "popular-restaurant",
    component: asyncComponent(() =>
      import("../Page/popular_restaurant/popularRestaurants")
    ),
  },
  {
    path: "promotional-coupon",
    component: asyncComponent(() =>
      import("../Page/promotional-coupon/coupon")
    ),
  },
  // {
  //   path: "promotional-coupon",
  //   component: asyncComponent(() =>
  //     import("../Page/promotional-coupen/coupen")
  //   ),
  // },
  {
    path: "broadcast",
    component: asyncComponent(() =>
      import("../Page/broadcast_notifications/broadcast")
    ),
  },
  {
    path: "offers",
    component: asyncComponent(() => import("../Page/offers/offers")),
  },
  {
    path: "customized-reports",
    component: asyncComponent(() =>
      import("../Page/customized_reports/reportsIndex")
    ),
  },
  {
    path: "manager-customize-report",
    component: asyncComponent(() =>
      import("../Page/customized_reports/reportsIndex")
    ),
  },
  {
    path: "email-template",
    component: asyncComponent(() =>
      import("../Page/email_template/emailTemplate")
    ),
  },
];

class AppRouter extends Component {
  render() {
    const { url, style } = this.props;

    return (
      <div style={style}>
        {routes.map((singleRoute) => {
          const { path, exact, ...otherProps } = singleRoute;
          return (
            <Route
              exact={exact === false ? false : true}
              key={singleRoute.path}
              path={`${url}/${singleRoute.path}`}
              {...otherProps}
            />
          );
        })}
        {/* <Redirect to={`/404`} /> */}
      </div>
    );
  }
}

export default AppRouter;
