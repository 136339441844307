const options = [
  {
    key: "dashboard",
    label: "Dashboard",
    leftIcon: "fas fa-tachometer-alt",
    is_admin: true,
  },
  {
    key: "users",
    label: "Users",
    leftIcon: "fas fa-users",
    is_admin: true,
  },
  {
    key: "drivers",
    label: "Drivers",
    leftIcon: "fas fa-user",
    is_admin: true,
  },
  {
    key: "coordinators",
    label: "Coordinators",
    leftIcon: "fas fa-user",
    is_admin: true,
  },
  {
    key: "area-management",
    label: "Zone Management",
    leftIcon: "fas fa-map-marker-alt",
    is_admin: true,
  },
  {
    key: "food-category",
    label: "Cuisine",
    leftIcon: "fas fa-utensils",
    is_admin: true,
  },
  {
    key: "subcategories",
    label: "Categories",
    leftIcon: "fas fa-utensils",
    is_admin: true,
  },
  {
    key: "restaurants",
    label: "Restaurants",
    leftIcon: "fas fa-hotel",
    is_admin: true,
  },

  {
    key: "enquiries",
    label: "Enquiries",
    leftIcon: "fas fa-comment-alt",
    is_admin: true,
  },
  {
    key: "static-pages",
    label: "Static pages",
    leftIcon: "fas fa-sticky-note",
    is_admin: true,
  },

  {
    key: "admin-setting",
    label: "Admin Settings",
    leftIcon: "fas fa-cog",
    is_admin: true,
  },

  {
    key: "orders",
    label: "Orders",
    leftIcon: "fas fa-shopping-cart",
    is_admin: true,
  },
  {
    key: "popular-restaurant",
    label: "Popular Restaurant",
    leftIcon: "fas fa-hotel",
    is_admin: true,
  },
  {
    key: "promotional-coupon",
    label: "Promotional Coupons",
    leftIcon: "fas fa-percent",
    is_admin: true,
  },
  {
    key: "broadcast",
    label: "Broadcast Notifications",
    leftIcon: "fas fa-bell",
    is_admin: true,
  },
  {
    key: "offers",
    label: "Offers",
    leftIcon: "fas fa-percent",
    is_admin: true,
  },
  {
    key: "customized-reports",
    label: "Customized Reports",
    leftIcon: "fas fa-file",
    is_admin: true,
  },
  {
    key: "email-template",
    label: "Email Templates",
    leftIcon: "ion-android-mail",
    is_admin: true,
  },

  // restaurant manager
  {
    key: "restaurant-products",
    label: "Products",
    leftIcon: "fas fa-utensils",
    is_manager: true,
  },
  {
    key: "manager-customize-report",
    label: "Customize Reports",
    leftIcon: "fas fa-file",
    is_manager: true,
  },
];
export default options;
