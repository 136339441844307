import React from "react";
import { Link } from "react-router-dom";
import { siteConfig } from "../../settings";
import logo from "../../image/food-court-icon.png";
export default ({ collapsed }) => {
  return (
    <div className="isoLogoWrapper">
      {collapsed ? (
        <div>
          <h3>
            <Link to="/admin">
              <img
                src={logo}
                alt="Icon"
                style={{ height: "50px", width: "50px" }}
              />
            </Link>
          </h3>
        </div>
      ) : (
        <h3>
          <Link to="/admin">{siteConfig.siteName}</Link>
        </h3>
      )}
    </div>
  );
};
