import { Map } from "immutable";
import actions from "./actions";

const initState = new Map({
  idToken: false,
  roles: ["admin"],
  user: null,
  token: null,
});

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_REQUEST:
      return state.set("user", action.user.user);
    case actions.LOGIN_SUCCESS: {
      if (action.token && action.token != null && action.token != undefined) {
        return state.set("idToken", action.token);
      } else {
        return state.set("idToken", action.profile.jwt_token);
      }
    }

    case actions.STORE_TOKEN: {
      return state.set("idToken", action.token.token);
    }

    case actions.CHECK_ROLES:
      return state.set("roles", action.data);
    case actions.LOGOUT:
      return initState;
    default:
      return state;
  }
}

// case actions.LOGIN_SUCCESS:
//       if (action.user.user !== null) {
//         return state.set("user", action.user.user);
//       } else {
//         return state.set("user", action.user.user);
//       }
